<template>
  <b-modal
    id="modal-add-product"
    title="Add Product"
    size="lg"
    title-class="text-black font-18"
    hide-footer
    no-close-on-backdrop
    @close="$emit('close')"
  >
    <div class="row">
      <div class="col-12">
        <b-alert
          v-model="isQtyLargerThanStock"
          variant="danger"
          class="mt-3"
          dismissible
        >Insufficient product stock</b-alert>
        <form @submit.prevent="addProduct"> 
          <div class="form-group">
            <label>Search Product From Surface +</label>
            <multiselect
              v-model="selectedWooProduct"
              label="name"
              track-by="name"
              placeholder="Typen Product Name"
              open-direction="bottom"
              :options="wooCommereceProduct"
              :multiple="false"
              :internal-search="true"
              :clear-on-select="false"
              :close-on-select="true"
              :hide-selected="false"
              :allowEmpty="false"
              @select="selectProduct"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                <strong>{{ option.name }}</strong>
              </template>
            </multiselect>
          </div>
          <div class="form-group">
            <label>Quantity</label>
            <input
              v-model="product.quantity"
              class="form-control"
              type="number"
              step="1"
              min="1"
              required
            />
          </div>
          <div class="form-group">
            <label>Price</label>
            <b-input-group prepend="Rp.">
              <input
                v-model="product.price"
                class="form-control"
                type="text"
                @keyup="formatRupiah"
                required
              />
            </b-input-group>
          </div>
          <button type="submit" class="btn btn-primary">Add Product To Appointment</button>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";

export default {
  name: "PopUpAddProduct",
  components: {
    Multiselect
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    location: {
      type: String,
      required: true
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.$bvModal.show('modal-add-product');
      } else {
        this.$bvModal.hide('modal-add-product')
        this.selectedWooProduct = null
        this.product = {
          id: null,
          name: null,
          price: null,
          quantity: null,
          status: null
        }
      }
    }
  },
  data() {
    return {
      wooCommereceProduct: [],
      selectedWooProduct: null,
      product: {
        id: null,
        name: null,
        price: null,
        quantity: null,
        status: null
      },
      isQtyLargerThanStock: false
    }
  },
  mounted() {
    this.findProduct()
  },
  methods: {
    formatRupiah() {
      const angka = this.convertToAngka(this.product.price)
      const rupaiah = this.convertToRupiah(angka, false)
      this.product.price = rupaiah
    },
    selectProduct(data){
      this.product = {
        id: data.id,
        name: data.name,
        price: this.convertToRupiah(data.price, false),
        quantity: 1,
        stock: data.stock
      }
    },
    addProduct() {
      // validate quantity
      if (Number(this.product.quantity) > this.product.stock) {
        this.isQtyLargerThanStock = true
        return false
      }
      // add to carts
      this.isQtyLargerThanStock = false
      this.$emit('add', {
        id: this.product.id,
        name: this.product.name,
        price: this.convertToAngka(this.product.price),
        quantity: this.product.quantity,
        stock: this.product.stock
      })
      // remove product form list products woo commerece
      const indexProduct = this.wooCommereceProduct.findIndex(prod => prod.id == this.product.id)
      if (indexProduct >= 0) {
        this.wooCommereceProduct.splice(indexProduct, 1)
      }
    },
    addProductToWooCommerece(product) {
      this.wooCommereceProduct.push({
        id: product.id,
        name: product.name,
        price: product.price,
        stock: product.stock
      })
    },
    findProduct() {
      axios.post(this.productUrl, {
        page: 1,
        per_page: 100,
        segment: {
          category: [],
          collection: [],
          concern: []
        },
        search: "",
        discount: "",
        order: "",
        orderby: "",
        min_price: "",
        max_price: "",
        location: this.location
      }).then( response => {
        if (response.data.status != 'fail') {
          this.wooCommereceProduct = response.data.data.products.filter(item => item.stock > 0).map(item => {
            return {
              id: item.id,
              name: item.title,
              price: item.price.default,
              stock: item.stock
            }
          }) 
        }
      })
        
    }
  }
}
</script>